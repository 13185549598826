<template>
  <div>
    <header>
      <h1
        class="text-white mt-1"
      >
        <a>
          <img
            :src="require('@/assets/images/logo/logo4.png')"
            height="35px"
            @click="move('top')"
          >
        </a>
      </h1>
      <nav class="gnav mt-1 pc">
        <ul class="menu">
          <!-- <li>
            <a
              class="listText"
              @click="move('jobs')"
            >
              求人情報
            </a>
          </li> -->
          <li>
            <a
              class="listText"
              @click="move('contact')"
            >
              お問い合わせ
            </a>
          </li>
          <li>
            <a
              class="listText"
              @click="move('login')"
            >
              会員ログイン
            </a>
          </li>
        </ul>
      </nav>
      <nav class="gnav mt-1 sm">
        <ul class="menu">
          <!-- <li>
            <a
              class="listText"
            >
              <feather-icon
                icon="BookmarkIcon"
                size="17"
                class=""
                @click="move('jobs')"
              />
            </a>
          </li> -->
          <li>
            <a
              class="listText"
            >
              <feather-icon
                icon="HelpCircleIcon"
                size="17"
                class=""
                @click="move('contact')"
              />
            </a>
          </li>
          <li>
            <a
              class="listText"
              @click="move('login')"
            >
              <feather-icon
                icon="LogInIcon"
                size="17"
                class=""
              />
            </a>
          </li>
        </ul>
      </nav>
    </header>
    <MailForm v-if="verifyEmailStatus == 1" />
    <VerifyEmail v-if="verifyEmailStatus == 2" />
    <CreateAccount v-if="verifyEmailStatus == 3" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MailForm from '@/components/authentication/MailForm.vue'
import VerifyEmail from '@/components/authentication/VerifyEmail.vue'
import CreateAccount from '@/components/authentication/CreateAccount.vue'

export default {
  components: {
    MailForm,
    VerifyEmail,
    CreateAccount,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('verifyEmail', ['verifyEmailStatus']),
  },
  mounted() {
    this.$store.dispatch('verifyEmail/updateVerifyEmailStatus', 1)
  },
  methods: {
    move(val) {
      // window.console.log('memo', val)
      this.$router.push({ name: val })
    },
  },
}
</script>

<style lang="scss">
header {
  width: 100%; /* 幅いっぱいを指定 */
  height: 60px; /* 高さを50pxに指定 */
  background: rgb(44, 43, 43); /* 背景色にグレーを指定 */
  padding: 20px 50px; /* ヘッダーに上下左右それぞれ余白を指定 */
  box-sizing: border-box; /* padding分を含んで幅を100%にするため */
  position: fixed; /* ウィンドウを基準に画面に固定 */
  top: 0; /* 上下の固定位置を上から0pxにする */
  left: 0; /* 左右の固定位置を左から0pxにする */
  display: flex; /* 中の要素を横並びにする */
  align-items: center; /* 中の要素を上下中央に並べる */
  z-index: 9999; /* 最も上の要素にする */
}
header .gnav .menu {
  display: flex; /* 中の要素を横並びにする */
  color: white
}
a {
  text-decoration: none;
}
header .gnav .menu li {
  list-style: none; /* リストの[・]を消す */
}

header .gnav .menu li + li {
  margin-left: 40px; /* メニューそれぞれに間隔をあけるため */
}
.sm {
  display: block;
}
@media screen and (min-width:896px){
  .sm {
    display: none;
  }
}
.pc {
  display: none;
}
@media screen and (min-width:896px){
  .pc {
    display: block;
  }
}
.listText {
  color: white;
  font-size: 1.2rem;
}
</style>
