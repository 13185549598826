<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- <vuexy-logo /> -->

          <h2 class="brand-text text-primary ml-1">
            転職コレクション
          </h2>
        </b-link>
        <div v-if="flag === 1">
          <div class="text-center mb-2">
            <b-button
              variant="outline-primary"
              block
              class="mt-2"
              @click="setFlag(2)"
            >
              <feather-icon
                icon="MailIcon"
                size="18"
              />
              メールアドレスで登録する
            </b-button>
            <div class="choice_">
              または
            </div>
            <b-button
              variant="outline-primary"
              block
              class=""
              @click="twitterLogin"
            >
              <feather-icon
                icon="TwitterIcon"
                size="18"
              />
              Twitterアカウントで登録する
            </b-button>
            <div class="mt-3 mt-3">
              <a @click="move('privacy')">
                <b>個人情報の取り扱い</b>
              </a>、および、
              <a @click="move('term')">
                <b>利用規約</b>
              </a>への<br>ご同意が必要です。
            </div>
          </div>
        </div>
        <div v-if="flag === 2">
          <b-card-title class="mb-1">
            ようこそ、転コレへ！
          </b-card-title>
          <b-card-text class="mb-2">
            このプラットフォームを利用するためにはアカウントの作成が必要です。
            メールアドレスを入力して｢送信する｣ボタンを押して下さい。
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
            >

              <!-- email -->
              <b-form-group
                label-for="email"
                label="Email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="mailaddress"
                    name="login-email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="john@example.com"
                    autofocus
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <div class="text-red">
                {{ errorMSG }}
              </div>
              <!-- submit button -->
              <b-button
                v-if="status == 1"
                variant="primary"
                block
                :disabled="invalid"
                style="margin: 10% 0;"
                @click="send"
              >
                送信する
              </b-button>
              <div
                v-if="status == 2"
                class="text-center mt-2"
              >
                <b-spinner
                  style="width: 3rem; height: 3rem;"
                  class="spinner_"
                  variant="primary"
                />
              </div>
            </b-form>
            <div class="text-center">
              <a
                @click="move('login')"
              >
                <small>アカウントをお持ちの方はこちらから</small>
              </a>
            </div>
          </validation-observer>
        </div>

        <!-- <b-card-text class="text-center mt-2">
          <span>New on our platform? </span>
          <b-link :to="{name:'auth-register-v1'}">
            <span>Create an account</span>
          </b-link>
        </b-card-text> -->

        <!-- <div class="divider my-2">
          <div class="divider-text">
            or
          </div>
        </div> -->

        <!-- social button -->
        <!-- <div class="auth-footer-btn d-flex justify-content-center">
          <b-button
            href="javascript:void(0)"
            variant="facebook"
          >
            <feather-icon icon="FacebookIcon" />
          </b-button>
          <b-button
            href="javascript:void(0)"
            variant="twitter"
          >
            <feather-icon icon="TwitterIcon" />
          </b-button>
          <b-button
            href="javascript:void(0)"
            variant="google"
          >
            <feather-icon icon="MailIcon" />
          </b-button>
          <b-button
            href="javascript:void(0)"
            variant="github"
          >
            <feather-icon icon="GithubIcon" />
          </b-button>
        </div> -->
      </b-card>
      <!-- /Login v1 -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BSpinner,
} from 'bootstrap-vue'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import sendVerifyEmail from '@/firestore/authentication/sendMail'
import firebase from 'firebase/app'
import store from '@/store'
import TwitterResister from '@/firestore/authentication/TwitterResister'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    // VuexyLogo,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      mailaddress: '',
      password: '',
      // validation rules
      required,
      email,
      status: 1,
      flag: 1,
      errorMSG: '',
    }
  },
  computed: {
    ...mapGetters('verifyEmail', ['verifyEmailStatus']),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    this.flag = Number(this.$route.query.flag) || 1
    this.$store.dispatch('userBasic/resetAll')
  },
  methods: {
    async send() {
      this.status = 2
      // window.console.log('🍎', this.mailaddress)
      const data = {
        mailaddress: this.mailaddress,
      }
      const response = await sendVerifyEmail(data)
      // window.console.log('🎉', response.status)
      if (response.status === 'success') {
        this.$store.dispatch('verifyEmail/updateVerifyEmailStatus', 2)
        this.$store.dispatch('verifyEmail/updateMailaddress', this.mailaddress)
      } else {
        // window.console.log('🎉', response.msg)
        this.status = 1
        this.errorMSG = response.msg
        // window.console.log('error', response.msg)
      }
    },
    makeToast() {
      const { type } = this.$route.query
      this.$bvToast.toast('現在はこの機能を制限しております。', {
        title: `type: ${type} `,
        variant: 'primary',
        solid: true,
      })
    },
    move(val) {
      // window.console.log('memo', val)
      this.$router.push({ name: val })
    },
    setFlag(val) {
      this.flag = val
    },
    async twitterLogin() {
      this.status = 2
      const provider = new firebase.auth.TwitterAuthProvider()
      // const provider = firebase.auth().TwitterAuthProvider()
      await firebase.auth().signInWithPopup(provider)
        .then(
          async result => {
            const { accessToken, secret } = result.credential
            const { user, additionalUserInfo } = result
            window.console.log('memo', secret.length)
            store.dispatch('userBasic/updateUid', user.uid)

            const d = {
              uid: user.uid,
              twitterName: additionalUserInfo.profile.screen_name,
            }
            const twitterResister = new TwitterResister()
            await twitterResister.add(d)

            localStorage.setItem('jwt', accessToken.toString())
            /* eslint-disable */
            const userData = {
              "id": user.uid,
              "fullName": "John Doe",
              "username": "johndoe",
              "avatar": "/img/13-small.d796bffd.png",
              "email": 'abc@sample.com',
              "role": "admin",
              "ability":[{"action":"manage",
              "subject":"all"}],
              "extras":{"eCommerceCartItemsCount":5},
            }
            localStorage.setItem('userData', JSON.stringify(userData))
            this.$ability.update(userData.ability)
            // ユーザタイプによって分ける：①コレクト→管理画面、②一般→ダッシュボード
            this.$router.push({ name: 'dashboad' })
            this.status = 1
            /* eslint-enable */
          },
        )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.spinner_ {
  margin: 3% auto;
}
.choice_ {
  margin: 4% 0;
  font-size: 0.8rem;
}
</style>
