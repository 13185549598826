// ①必要な情報を作る
// ②メールを送信する→mail.jsのメソッドを呼び出す
import axios from 'axios'
import sendEmail from '@/firestore/authentication/mail'
import registerVerifyNumber from '@/firestore/authentication/registerNumber'

export default async function sendVerifyEmail(payload) {
  const { mailaddress } = payload
  try {
    let isUsedAddress = false
    const uri = 'https://us-central1-collect-staging-env.cloudfunctions.net/api/isUsedAddress/'
    const uriget = uri + mailaddress
    await axios.get(uriget)
      .then(response => {
        isUsedAddress = response.data
      })
    if (isUsedAddress) {
      const response = {
        status: 'error',
        msg: 'このメールアドレスは既に使用されています。',
      }
      return response
    }
    const res = await registerVerifyNumber(mailaddress) // 乱数の生成
    if (res.status === 'success') {
      const response = await sendEmail(res) // 招待メールの送信
      return response
    }
    const response = {
      status: 'error',
      msg: 'メールアドレス有効性確認番号が生成されませんでした。',
    }
    return response
  } catch (error) {
    const response = {
      status: 'error',
      msg: error,
    }
    return response
  }
}
