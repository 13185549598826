import firebase from 'firebase/app'
import mail from '@/firestore/mail/msg'

export default async function sendEmail(payload) {
  try {
    const { mailaddress, verifyNumber } = payload
    // window.console.log('🐶', mailaddress, verifyNumber)
    // const verifyNumber = payload.verifyNumber

    const index1 = mail.findIndex(v => v.id === 11)
    const msg = mail[index1]
    let text = String(msg.text)
    text = String(text).replace(/VERIFY_NUMBER/g, verifyNumber)

    const mailTo = []
    mailTo.push(mailaddress)
    // // mailTo.push(prote_mail)

    const content = {
      bcc: mailTo,
      message: {
        subject: msg.title,
        text,
      },
      category: 'verifyEmail',
    }
    await firebase.firestore().collection('mail').add(content)
    const response = {
      status: 'success',
    }
    return response
  } catch (error) {
    const response = {
      status: 'error',
      msg: 'ユーザ登録の招待メールが送信されませんでした。',
    }
    return response
  }
}
